import { roleCanEdit } from "@/service/role_service.js";

/**
 * Un mixin outil pour la gestion dse droits d'accès de l'utilisateur
 *
 */
export let RoleMixin = {
  data() {
    return {
      /**est-ce que l'utilisateur peut éditer les données? (en fonction de son rôle)*/
      canEdit: false,
    };
  },
  methods: {
    roleCanEdit() {
      return this.canEdit;
    },
  },
  mounted() {
    //est-ce que l'utilisateur peut éditer les données
    this.canEdit = roleCanEdit();
  },
};

export default RoleMixin;
