/**
 * Un mixin outil pour l'ajout des étapes du workflow
 *
 */
export let TableViewEditWorkflowMixin = {
  data() {
    return {};
  },
  methods: {
    /** Initialisation des étapes du workflow en fonction des droits de l'utilisateur.*/
    initTableViewEditWorkflow(canEdit, index = 0) {
      this.addStepForWorkflow("Choisir une table");
      if (canEdit) {
        this.addStepForWorkflow("Consulter/Modifier la table");
      } else {
        this.addStepForWorkflow("Consulter la table");
      }

      this.goToStepForWorkflow(index);
    },
  },
  mounted() {},
};

export default TableViewEditWorkflowMixin;
