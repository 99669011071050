/**
 * Un mixin outil pour la manipulation de la snackbar sur une view/composant
 * 
     <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
    >
      {{snackbarMessage}}
      <!-- <v-btn text color="primary" @click.native="snackbar = false">Fermer</v-btn> -->
    </v-snackbar>
 */
export let SnackBarMixin = {
  data() {
    return {
      /**Est-ce que la snackbar est visible?*/
      snackbarVisible: false,

      /**le message à afficher  */
      snackbarMessage: "",

      /**la couleur par défaut*/
      snackbarColor: "primary",

      /**la durée d'affichage de la snackbar */
      snackbarTimeout: 6000,

      snackbarLeft: false,
      snackbarRight: false,
      snackbarTop: false,
      snackbarBottom: true,
    };
  },
  methods: {
    addMessageToSnackbar(color, message) {
      this.snackbarColor = color;
      this.snackbarMessage = message;
      this.snackbarVisible = true;
    },
    addErrorToSnackbar(message) {
      this.addMessageToSnackbar("error", message);
    },
    addInfoToSnackbar(message) {
      this.addMessageToSnackbar("info", message);
    },
  },
  mounted() {},
};

export default SnackBarMixin;
