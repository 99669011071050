/**
 * Compare les valeurs des deux textes et retourne true s'ils sont identiques.
 * Cette fonction prend en compte la comparaison avec les valeurs : null, undefined, "".
 * @param {String} text1
 * @param {String} text2
 */
export function areTheSame(text1, text2) {
  if (!!text1 && !!text2) {
    return text1 == text2;
  } else if (!!text1 && !text2) {
    return false;
  } else if (!text1 && !!text2) {
    return false;
  } else {
    return true;
  }
}

/**
 * Retourne true si la chaine de caractère est nulle, undefined, vide.
 * @param {String} value
 */
export function isNullOrEmpty(value) {
  return (value == null || value == undefined || value === "");
}

/**
 * Retourne true si la chaine de caractère est nulle, undefined, vide ou avec uniquement des espaces
 * @param {String} value
 */
export function isNullOrEmptyOrSpaces(value) {
  return (
    value == null ||
    value == undefined ||
    value === "" ||
    value.match(/^ *$/) !== null
  );
}

/**
 * Retourne un tiret si la string est :
 * - null
 * - undefined
 * - ""
 * - "null"
 * @param {String} value
 */
export function replaceNullOrUndefineByHyphen(value) {
  if (isNullOrEmpty(value) || value == "null") {
    return "-";
  } else {
    return value;
  }
}

/** Retourne true si toutes les paires (string) du tableaux sont identiques. array = [[a, b], [c, c], ....]
 * @param {*} array
 */
export function areTheSames(array) {
  for (const values of array) {
    if (!areTheSame(values[0], values[1])) {
      return false;
    }
  }

  return true;
}

/**Trier la liste par ordre alphabétique en fonction d'une propriété.*/
export function sortByProperty(list, property) {
  list.sort(function(a, b) {
    if (!a || !b) return 0;

    return a[property].toLowerCase().localeCompare(b[property].toLowerCase());
  });
}

/** Met en capitale la première lettre d'une chaine texte */
export function capitalizeFirstLetter(string) {
  if (!string) {
    return "";
  }

  const text = string.toLowerCase();

  return text.charAt(0).toUpperCase() + text.slice(1);
}

/** Supprime les espaces d'une chaine texte */
export function trimString(value) {
  if (!value) {
    return value;
  }
  else {
    return value.trim();
  }
}